
import Vue from 'vue';

import theme from 'Theme';
import AssetValue from 'UI/AssetValue.vue';
import Button from 'Control/Button.vue';
import Borrowing from 'Entities/privatePresenter/Borrowing';
import InternalUserResponse from 'Entities/userLoginHistory/InternalUserResponse';
import ApiError from 'Entities/ApiError';
import TradingApi from 'Apis/Trading';
import QuickRepaymentRequest from 'Entities/orderRegistrator/QuickRepaymentRequest';
import { composedPath } from 'Lib/utils/eventPathChecker';

type Data = {
    theme: typeof theme;
    showTooltip: boolean;
    isButtonDisabled: boolean;
    timeoutId: null | ReturnType<typeof setTimeout>;
};

type Methods = {
    setShowTooltip: () => void;
    startClosingProcess: () => void;
    repay: () => void;
    clearTimeoutId: () => void;
};

type Computed = {
    currentUser: InternalUserResponse | undefined;
    isKycVerified: boolean;
};

type Props = {
    borrowing: Borrowing;
};

export default Vue.extend<Data, Methods, Computed, Props>({
    name: 'BorrowingsRow',
    components: {
        Button,
        AssetValue,
    },
    props: {
        borrowing: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            theme,
            showTooltip: false,
            isButtonDisabled: false,
            timeoutId: null,
        };
    },
    computed: {
        currentUser() {
            return this.$store.state.User.currentUser;
        },
        isKycVerified() {
            if (!this.currentUser) {
                return false;
            }
            return this.currentUser.kycStatus === 'Verified';
        },
    },
    methods: {
        setShowTooltip() {
            this.showTooltip = true;
        },
        startClosingProcess() {
            this.timeoutId = setTimeout(() => {
                this.repay();
            }, 1500);
        },
        clearTimeoutId() {
            if (!this.timeoutId) {
                return;
            }
            clearTimeout(this.timeoutId);
            this.timeoutId = null;
        },
        async repay() {
            try {
                this.isButtonDisabled = true;
                await TradingApi.privateOrdersQuickRepayment(new QuickRepaymentRequest({
                    accountId: this.borrowing.accountId,
                    currency: this.borrowing.assetSymbol,
                    placementTag: this.borrowing.placementTag,
                }));
                await this.$store.dispatch('Notificator/showЫгссуыыNotification', 'Repayment has been successfully performed', { root: true });
            } catch (error) {
                if (error instanceof ApiError) {
                    await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Something Went Wrong', { root: true });
                }
            } finally {
                this.isButtonDisabled = false;
            }
        },
    },
    mounted() {
        window.addEventListener('mouseup', () => {
            this.clearTimeoutId();
        });
        window.addEventListener('click', (event) => {
            const path = composedPath(event.target);
            if (!path.some(({ classList }) => classList && Array.from(classList).includes('hold-button'))) {
                this.showTooltip = false;
            }
        });
    },
});
