<template>
    <div>
        <table :class="[theme.table.table, s.relative, s.w100, {[s.h100]: (borrowings.length === 12 || isLoading)}]">
            <thead>
            <tr>
                <th :class="[theme.table.headerText, theme.table.alignLeft]">
                    placement
                </th>
                <th :class="[theme.table.headerText, theme.table.alignCenter]">
                    asset
                </th>
                <th :class="[theme.table.headerText, theme.table.alignCenter]">
                    borrowed amount
                </th>
                <th :class="[theme.table.headerText, theme.table.alignCenter]">
                    hourly interest rate
                </th>
                <th :class="[theme.table.headerText, theme.table.alignCenter]">
                    max. borrowed amount
                </th>
                <th :class="[theme.table.headerText, theme.table.alignRight]">
                    action
                </th>
            </tr>
            </thead>
            <BorrowingsRow
                v-for="borrowing in borrowings"
                :key="`${borrowing.assetSymbol}${borrowing.placementName}${borrowing.accountId}`"
                :borrowing="borrowing"
            />
            <Preloader
                v-if="isLoading"
                :class="s.preloader"
            />
        </table>
    </div>
</template>

<script>
import theme from 'Theme';
import BorrowingsRow from 'Modules/OrdersHistory/components/BorrowingsRow.vue';

export default {
    name: 'BorrowingsTable',
    components: {
        BorrowingsRow,
    },
    props: {
        borrowings: {
            type: Array,
            required: true,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            theme,
        };
    },
};
</script>

<style lang="postcss" module="s">
.h100 {
    height: 100%;
}
.w100 {
    width: 100%;
}
.relative {
    position: relative;
}
.preloader {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
</style>
