// This file was autogenerated. Please do not change.
export interface IQuickRepaymentRequest {
    accountId?: string;
    currency?: string;
    placementTag?: string;
}

export default class QuickRepaymentRequest {
    readonly _accountId: string | undefined;

    /**
     * Example: AX5RL7F7P7C
     */
    get accountId(): string | undefined {
        return this._accountId;
    }

    readonly _currency: string | undefined;

    /**
     * Example: USDT
     */
    get currency(): string | undefined {
        return this._currency;
    }

    readonly _placementTag: string | undefined;

    /**
     * Example: BYBIT
     */
    get placementTag(): string | undefined {
        return this._placementTag;
    }

    constructor(props: IQuickRepaymentRequest) {
        if (typeof props.accountId === 'string') {
            this._accountId = props.accountId.trim();
        }
        if (typeof props.currency === 'string') {
            this._currency = props.currency.trim();
        }
        if (typeof props.placementTag === 'string') {
            this._placementTag = props.placementTag.trim();
        }
    }

    serialize(): IQuickRepaymentRequest {
        const data: IQuickRepaymentRequest = {
        };
        if (typeof this._accountId !== 'undefined') {
            data.accountId = this._accountId;
        }
        if (typeof this._currency !== 'undefined') {
            data.currency = this._currency;
        }
        if (typeof this._placementTag !== 'undefined') {
            data.placementTag = this._placementTag;
        }
        return data;
    }

    toJSON(): IQuickRepaymentRequest {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            accountId: !this._accountId ? true : typeof this._accountId === 'string',
            currency: !this._currency ? true : typeof this._currency === 'string',
            placementTag: !this._placementTag ? true : typeof this._placementTag === 'string',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
