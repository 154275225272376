var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('tbody',{class:_vm.s.w100},[_c('tr',[_c('td',{class:[_vm.theme.table.alignLeft, _vm.theme.table.placementContent]},[_c('div',{class:_vm.s.row},[_c('PlacementIcon',{class:[_vm.s.mrXs, _vm.s.alignSelfCenter],attrs:{"placement":_vm.borrowing.placementName}}),_c('span',{class:_vm.s.alignSelfCenter},[_vm._v(" "+_vm._s(_vm.borrowing.placementName)+" ")])],1)]),_c('td',{class:[_vm.theme.table.alignCenter]},[_c('div',{class:[_vm.s.row, _vm.s.center]},[_c('CryptoIcon',{class:[_vm.s.mrXs, _vm.s.alignSelfCenter],attrs:{"symbol":_vm.borrowing.assetSymbol}}),_c('span',{class:_vm.s.alignSelfCenter},[_vm._v(" "+_vm._s(_vm.borrowing.assetSymbol)+" ")])],1)]),_c('td',{class:[_vm.theme.table.alignCenter, _vm.theme.table.ordersTableBoldContent, _vm.theme.table.gray]},[_c('AssetValue',{attrs:{"value":_vm.borrowing.amount.noExponents().getSeparatedDigits(),"asset":_vm.borrowing.assetSymbol,"value-class":_vm.theme.table.ordersHistoryValueContent,"need-to-round":false,"is-aligned-center":""}})],1),_c('td',{class:[_vm.theme.table.alignCenter, _vm.theme.table.ordersHistoryValueContent]},[_vm._v(" "+_vm._s(_vm.borrowing.rate)+"% ")]),_c('td',{class:[_vm.theme.table.alignCenter, _vm.theme.table.ordersTableBoldContent]},[_c('AssetValue',{attrs:{"value":_vm.borrowing.maxAmount.noExponents().getSeparatedDigits(),"asset":_vm.borrowing.assetSymbol,"value-class":_vm.theme.table.ordersHistoryValueContent,"need-to-round":false,"is-aligned-center":""}})],1),_c('td',{class:[_vm.theme.table.alignRight, _vm.theme.table.ordersTableBoldContent]},[_c('Button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                placement: 'auto',
                content: 'To make quick repayment please hold the button',
                trigger: 'manual',
                container: false,
                offset: 15,
                hideOnTargetClick: true,
                show: _vm.showTooltip,
            }),expression:"{\n                placement: 'auto',\n                content: 'To make quick repayment please hold the button',\n                trigger: 'manual',\n                container: false,\n                offset: 15,\n                hideOnTargetClick: true,\n                show: showTooltip,\n            }"}],staticClass:"hold-button",class:_vm.s.button,attrs:{"button-disabled":_vm.isButtonDisabled || !_vm.isKycVerified,"button-type":"primary"},on:{"click":_vm.setShowTooltip,"mousedown":_vm.startClosingProcess}},[[_vm._v(" repay ")]],2)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }