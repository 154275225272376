<template>
    <div id="ordersHistoryModule" class="module orders-history" :class="s.relative">
        <div :class="s.header" class="draggable">
            <div :class="s.subHeader">
                <AccountColorMarker :color="activeAccountColor" :size="12" style="margin-right: 8px" />
                <BlockNavbar
                    :items="NavBar"
                    :value="activeTabIndex"
                    @change="setActiveOrdersTab"
                />
            </div>
            <HeaderSwitcher
                v-if="!isOTC"
                :first-variant-text="ordersVariants[0]"
                :second-variant-text="ordersVariants[1]"
                :active-index="activeOrderVariant"
                @change="switchHeader($event)"
            />
        </div>
        <NoData
            v-if="isListEmpty"
            :text="noDataMessage"
        />
        <div :class="s.isOverfloved">
            <template v-if="!isCurrentPlacementFutures && !isOTC">
                <TradesTable
                    :class="{[s.h100]: (spotTrades && spotTrades.length === 12) || $store.state.Orders.History.tradesData.isLoading}"
                    :trades="spotTrades || []"
                    v-if="tradesTabActive"
                    :is-loading="$store.state.Orders.History.tradesData.isLoading"
                />
                <OrdersTable
                    :is-otc="isOTC"
                    :class="{[s.h100]: (openSpotOrders && openSpotOrders.length === 12) || $store.state.Orders.History.openOrdersData.isLoading}"
                    :orders="openSpotOrders || []"
                    v-else-if="activeOrdersTabActive"
                    :is-loading="$store.state.Orders.History.openOrdersData.isLoading"
                />
                <OrdersTable
                    :is-otc="isOTC"
                    :class="{[s.h100]: (closedSpotOrders && closedSpotOrders.length === 12) || $store.state.Orders.History.closedOrdersData.isLoading}"
                    :orders="closedSpotOrders || []"
                    v-else-if="closedOrdersTabActive"
                    :is-loading="$store.state.Orders.History.closedOrdersData.isLoading"
                />
                <BorrowingsTable
                    :borrowings="paginatedBorrowings"
                    v-else-if="borrowingsTabActive"
                    :is-loading="$store.state.Orders.History.borrowingsData.isLoading"
                    :class="{[s.h100]: (paginatedBorrowings && paginatedBorrowings.length === 12) || $store.state.Orders.History.borrowingsData.isLoading}"
                />
                <OrdersTable
                    v-else
                    :is-otc="isOTC"
                    :class="{[s.h100]: (spotOrders && spotOrders.length === 12) || $store.state.Orders.History.ordersData.isLoading}"
                    :orders="spotOrders || []"
                    :is-loading="$store.state.Orders.History.ordersData.isLoading"
                />
            </template>
            <template v-else-if="isCurrentPlacementFutures && !isOTC">
                <FuturesTradesTable
                    :class="{[s.h100]: (futuresTrades && futuresTrades.length === 12) || $store.state.Futures.tradesData.isLoading}"
                    v-if="tradesTabActive"
                    :trades="futuresTrades || []"
                    :is-loading="$store.state.Futures.tradesData.isLoading"
                />
                <PositionsTable
                    :class="{[s.h100]: paginatedPositions.length === 12}"
                    v-else-if="positionsTabActive"
                    :positions="paginatedPositions"
                />
                <FundingsTable
                    :class="{[s.h100]: (fundings && fundings.length === 12) || $store.state.Futures.fundingsData.isLoading}"
                    v-else-if="fundingsTabActive"
                    :fundings="fundings || []"
                    :is-loading="$store.state.Futures.fundingsData.isLoading"
                />
                <FuturesOrdersTable
                    :class="{[s.h100]: (futuresClosedOrders && futuresClosedOrders.length === 12) || $store.state.Futures.closedOrdersData.isLoading}"
                    v-else-if="closedOrdersTabActive"
                    :orders="futuresClosedOrders || []"
                    :is-loading="$store.state.Futures.closedOrdersData.isLoading"
                />
                <FuturesOrdersTable
                    :class="{[s.h100]: (futuresOpenOrders && futuresOpenOrders.length === 12) || $store.state.Futures.openOrdersData.isLoading}"
                    v-else-if="activeOrdersTabActive"
                    :orders="futuresOpenOrders || []"
                    :is-loading="$store.state.Futures.openOrdersData.isLoading"
                />
                <FuturesOrdersTable
                    :class="{[s.h100]: (futuresOrders && futuresOrders.length === 12) || $store.state.Futures.ordersData.isLoading}"
                    v-else
                    :orders="futuresOrders || []"
                    :is-loading="$store.state.Futures.ordersData.isLoading"
                />
            </template>
            <template v-else-if="isOTC">
                <TradesTable
                    :class="{[s.h100]: (otcSpotTrades && otcSpotTrades.length === 12) || $store.state.Orders.History.otcTradesData.isLoading}"
                    :trades="otcSpotTrades || []"
                    v-if="tradesTabActive"
                    :is-loading="$store.state.Orders.History.otcTradesData.isLoading"
                />
                <OrdersTable
                    :is-otc="isOTC"
                    :class="{[s.h100]: (otcOpenSpotOrders && otcOpenSpotOrders.length === 12) || $store.state.Orders.History.otcOpenOrdersData.isLoading}"
                    :orders="otcOpenSpotOrders || []"
                    v-else-if="activeOrdersTabActive"
                    :is-loading="$store.state.Orders.History.otcOpenOrdersData.isLoading"
                />
                <OrdersTable
                    :is-otc="isOTC"
                    :class="{[s.h100]: (otcClosedSpotOrders && otcClosedSpotOrders.length === 12) || $store.state.Orders.History.otcClosedOrdersData.isLoading}"
                    :orders="otcClosedSpotOrders || []"
                    v-else-if="closedOrdersTabActive"
                    :is-loading="$store.state.Orders.History.otcClosedOrdersData.isLoading"
                />
                <OrdersTable
                    v-else
                    :is-otc="isOTC"
                    :class="{[s.h100]: (otcSpotOrders && otcSpotOrders.length === 12) || $store.state.Orders.History.otcOrdersData.isLoading}"
                    :orders="otcSpotOrders || []"
                    :is-loading="$store.state.Orders.History.otcOrdersData.isLoading"
                />
            </template>
        </div>
        <div
            :class="s.paginator"
        >
            <Pagination
                v-if="!isOTC && !isCurrentPlacementFutures && !positionsTabActive && !closedOrdersTabActive && !activeOrdersTabActive && !tradesTabActive"
                :page="spotOrdersPage"
                @change="spotOrdersPage = $event"
                :pages-quantity="spotOrdersPageCount"
            />
            <Pagination
                v-else-if="!isOTC && !isCurrentPlacementFutures && !positionsTabActive && !closedOrdersTabActive && activeOrdersTabActive"
                :page="closedSpotOrdersPage"
                @change="closedSpotOrdersPage = $event"
                :pages-quantity="openOrdersPageCount"
            />
            <Pagination
                v-else-if="!isOTC && !isCurrentPlacementFutures && !positionsTabActive && closedOrdersTabActive && !activeOrdersTabActive"
                :page="closedSpotOrdersPage"
                @change="closedSpotOrdersPage = $event"
                :pages-quantity="closedOrdersPageCount"
            />
            <Pagination
                v-else-if="!isOTC && !isCurrentPlacementFutures && tradesTabActive"
                :page="spotTradesPage"
                @change="spotTradesPage = $event"
                :pages-quantity="spotTradesPageCount"
            />
            <Pagination
                v-else-if="!isOTC && !isCurrentPlacementFutures && borrowingsTabActive"
                :page="borrowingsPage"
                @change="borrowingsPage = $event"
                :pages-quantity="borrowingsPageCount"
            />
            <Pagination
                v-else-if="!isOTC && isCurrentPlacementFutures && tradesTabActive"
                :page="futuresTradesPage"
                @change="futuresTradesPage = $event"
                :pages-quantity="futuresTradesPageCount"
            />
            <Pagination
                v-else-if="!isOTC && positionsTabActive"
                :page="activePositionsPage"
                @change="activePositionsPage = $event"
                :pages-quantity="positionsPagesCount"
            />
            <Pagination
                v-else-if="!isOTC && fundingsTabActive"
                :page="fundingsPage"
                @change="fundingsPage = $event"
                :pages-quantity="fundingsPageCount"
            />
            <Pagination
                v-else-if="!isOTC && isCurrentPlacementFutures && !positionsTabActive && !closedOrdersTabActive && !activeOrdersTabActive && !tradesTabActive"
                :page="futuresOrdersPage"
                @change="futuresOrdersPage = $event"
                :pages-quantity="futuresOrdersPageCount"
            />
            <Pagination
                v-else-if="!isOTC && isCurrentPlacementFutures && !positionsTabActive && closedOrdersTabActive && !activeOrdersTabActive && !tradesTabActive"
                :page="futuresClosedOrdersPage"
                @change="futuresClosedOrdersPage = $event"
                :pages-quantity="futuresClosedOrdersPageCount"
            />
            <Pagination
                v-else-if="!isOTC && isCurrentPlacementFutures && !positionsTabActive && !closedOrdersTabActive && activeOrdersTabActive && !tradesTabActive"
                :page="futuresOpenOrdersPage"
                @change="futuresOpenOrdersPage = $event"
                :pages-quantity="futuresOpenOrdersPageCount"
            />
            <Pagination
                v-else-if="isOTC && !positionsTabActive && !closedOrdersTabActive && activeOrdersTabActive && !tradesTabActive"
                :page="otcOpenSpotOrdersPage"
                @change="otcOpenSpotOrdersPage = $event"
                :pages-quantity="otcOpenOrdersPageCount"
            />
            <Pagination
                v-else-if="isOTC && !positionsTabActive && closedOrdersTabActive && !activeOrdersTabActive && !tradesTabActive"
                :page="otcClosedSpotOrdersPage"
                @change="otcClosedSpotOrdersPage = $event"
                :pages-quantity="otcClosedOrdersPageCount"
            />
            <Pagination
                v-else-if="isOTC && !positionsTabActive && !closedOrdersTabActive && !activeOrdersTabActive && tradesTabActive"
                :page="otcSpotTradesPage"
                @change="otcSpotTradesPage = $event"
                :pages-quantity="otcSpotTradesPageCount"
            />
            <Pagination
                v-else-if="isOTC && !positionsTabActive && !closedOrdersTabActive && !activeOrdersTabActive && !tradesTabActive"
                :page="otcSpotOrdersPage"
                @change="otcSpotOrdersPage = $event"
                :pages-quantity="otcSpotOrdersPageCount"
            />
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';

import {
    getClosedOrders, getFundings, getOpenOrders,
    getOrders,
    getTablePositions, getTrades, SET_CURRENT_POSITIONS_PAGE,
} from 'Store/v2/Futures';
import AccountColorMarker from 'Common/AccountColorMarker.vue';
import BlockNavbar from 'Control/BlockNavbar.vue';
import Pagination from 'Control/Pagination.vue';
import NoData from 'UI/NoData.vue';
import { OrdersHistoryTypes } from 'Store/modules/Orders/History';
import HeaderSwitcher from 'Control/HeaderSwitcher.vue';
import TradesTable from 'Modules/OrdersHistory/components/TradesTable.vue';
import FuturesTradesTable from 'Modules/OrdersHistory/components/FuturesTradesTable.vue';
import FundingsTable from 'Modules/OrdersHistory/components/FundingsTable.vue';
import FuturesOrdersTable from 'Modules/OrdersHistory/components/FuturesOrdersTable.vue';
import PositionsTable from 'Modules/OrdersHistory/components/PositionsTable.vue';
import BorrowingsTable from 'Modules/OrdersHistory/components/BorrowingsTable.vue';

import OrdersTable from './components/OrdersTable.vue';

export default Vue.extend({
    name: 'Orders',
    props: {
        isOTC: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        BorrowingsTable,
        FuturesOrdersTable,
        FundingsTable,
        FuturesTradesTable,
        OrdersTable,
        PositionsTable,
        AccountColorMarker,
        BlockNavbar,
        Pagination,
        NoData,
        HeaderSwitcher,
        TradesTable,
    },
    data() {
        return {
            OrdersHistoryTypes,
            ordersVariants: ['spot', 'futures'],
            activeOrderVariant: 0,

            spotOrdersPage: 1,
            closedSpotOrdersPage: 1,
            openSpotOrdersPage: 1,
            spotTradesPage: 1,
            borrowingsPage: 1,
            otcSpotOrdersPage: 1,
            otcClosedSpotOrdersPage: 1,
            otcOpenSpotOrdersPage: 1,
            otcSpotTradesPage: 1,
            futuresTradesPage: 1,
            futuresOrdersPage: 1,
            futuresClosedOrdersPage: 1,
            futuresOpenOrdersPage: 1,
            fundingsPage: 1,
        };
    },
    computed: {
        ...mapGetters({
            activeTabIndex: 'Orders/History/activeTerminalModuleTabIndex',
            tradesTabActive: 'Orders/History/isTradesTabActive',
            borrowingsTabActive: 'Orders/History/isBorrowingsTabActive',
            positionsTabActive: 'Orders/History/isPositionsTabActive',
            fundingsTabActive: 'Orders/History/isFundingsTabActive',
            isListUpdating: 'Orders/History/isListUpdating',
            activeAccountId: 'Accounts/activeAccountID',
            activeAccount: 'Accounts/activeAccount',
            isThemeDark: 'isThemeDark',
        }),
        isListEmpty() {
            const spotStore = this.$store.state.Orders.History;
            const futuresStore = this.$store.state.Futures;
            if (this.isOTC) {
                if (this.activeOrdersTabActive) {
                    return (this.otcOpenSpotOrders || []).length === 0 && !spotStore.otcOpenOrdersData.isLoading;
                }
                if (this.closedOrdersTabActive) {
                    return (this.otcClosedSpotOrders || []).length === 0 && !spotStore.otcClosedOrdersData.isLoading;
                }
                if (this.tradesTabActive) {
                    return (this.otcSpotTrades || []).length === 0 && !spotStore.otcTradesData.isLoading;
                }
                return (this.otcSpotOrders || []).length === 0 && !spotStore.otcOrdersData.isLoading;
            }
            if (!this.isCurrentPlacementFutures) {
                if (this.activeOrdersTabActive) {
                    return (this.openSpotOrders || []).length === 0 && !spotStore.openOrdersData.isLoading;
                }
                if (this.closedOrdersTabActive) {
                    return (this.closedSpotOrders || []).length === 0 && !spotStore.closedOrdersData.isLoading;
                }
                if (this.tradesTabActive) {
                    return (this.spotTrades || []).length === 0 && !spotStore.tradesData.isLoading;
                }
                if (this.borrowingsTabActive) {
                    return this.borrowings.length === 0 && !spotStore.borrowingsData.isLoading;
                }
                return (this.spotOrders || []).length === 0 && !spotStore.ordersData.isLoading;
            }
            if (this.positionsTabActive) {
                return (this.positions || []).length === 0;
            }
            if (this.fundingsTabActive) {
                return (this.fundings || []).length === 0 && !futuresStore.fundingsData.isLoading;
            }
            if (this.activeOrdersTabActive) {
                return (this.futuresOpenOrders || []).length === 0 && !futuresStore.openOrdersData.isLoading;
            }
            if (this.closedOrdersTabActive) {
                return (this.futuresClosedOrders || []).length === 0 && !futuresStore.closedOrdersData.isLoading;
            }
            if (this.tradesTabActive) {
                return (this.futuresTrades || []).length === 0 && !futuresStore.tradesData.isLoading;
            }
            return (this.futuresOrders || []).length === 0 && !futuresStore.ordersData.isLoading;
        },
        activePositionsPage: {
            get() {
                return this.$store.state.Futures.ui.positionsCurrentPage;
            },
            set(number) {
                this.$store.commit(SET_CURRENT_POSITIONS_PAGE(Number(number)));
            },
        },
        spotOrders() {
            const { orders } = this.$store.state.Orders.History.ordersData;
            return orders.get(this.spotOrdersPage);
        },
        closedSpotOrders() {
            const { orders } = this.$store.state.Orders.History.closedOrdersData;
            return orders.get(this.closedSpotOrdersPage);
        },
        openSpotOrders() {
            const { orders } = this.$store.state.Orders.History.openOrdersData;
            return orders.get(this.openSpotOrdersPage);
        },
        spotTrades() {
            const { trades } = this.$store.state.Orders.History.tradesData;
            return trades.get(this.spotTradesPage);
        },
        otcSpotOrders() {
            const { orders } = this.$store.state.Orders.History.otcOrdersData;
            return orders.get(this.otcSpotOrdersPage);
        },
        otcClosedSpotOrders() {
            const { orders } = this.$store.state.Orders.History.otcClosedOrdersData;
            return orders.get(this.otcClosedSpotOrdersPage);
        },
        otcOpenSpotOrders() {
            const { orders } = this.$store.state.Orders.History.otcOpenOrdersData;
            return orders.get(this.otcOpenSpotOrdersPage);
        },
        otcSpotTrades() {
            const { trades } = this.$store.state.Orders.History.otcTradesData;
            return trades.get(this.otcSpotTradesPage);
        },
        futuresTrades() {
            const { trades } = this.$store.state.Futures.tradesData;
            return trades.get(this.futuresTradesPage);
        },
        futuresOrders() {
            const { orders } = this.$store.state.Futures.ordersData;
            return orders.get(this.futuresOrdersPage);
        },
        futuresClosedOrders() {
            const { orders } = this.$store.state.Futures.closedOrdersData;
            return orders.get(this.futuresClosedOrdersPage);
        },
        futuresOpenOrders() {
            const { orders } = this.$store.state.Futures.openOrdersData;
            return orders.get(this.futuresOpenOrdersPage);
        },
        fundings() {
            const { fundings } = this.$store.state.Futures.fundingsData;
            return fundings.get(this.fundingsPage);
        },
        borrowings() {
            return this.$store.state.Orders.History.borrowingsData.borrowings;
        },
        activeOrdersTabActive() {
            return this.$store.getters['Orders/History/isActiveTabActive'];
        },
        closedOrdersTabActive() {
            return this.$store.getters['Orders/History/isClosedTabActive'];
        },
        noDataMessage() {
            if (this.tradesTabActive) {
                return 'There are currently no trades.';
            }
            if (this.borrowingsTabActive) {
                return 'There are currently no borrowings.';
            }
            if (this.positionsTabActive) {
                return 'There are currently no positions.';
            }
            if (this.fundingsTabActive) {
                return 'There are currently no fundings.';
            }
            return 'There are currently no orders.';
        },
        activeAccountColor() {
            if (!this.activeAccount || !this.activeAccount.color) {
                return this.isThemeDark ? '#23232A' : '#f1f2f5';
            }
            return this.activeAccount.color;
        },
        isCurrentPlacementFutures() {
            return this.activeOrderVariant === 1;
        },
        spotOrdersPageCount() {
            return this.$store.state.Orders.History.ordersData.totalPages;
        },
        borrowingsPageCount() {
            const store = this.$store.state.Orders.History.borrowingsData;
            return Math.ceil(store.borrowings.length / store.perPage);
        },
        closedOrdersPageCount() {
            return this.$store.state.Orders.History.closedOrdersData.totalPages;
        },
        spotTradesPageCount() {
            return this.$store.state.Orders.History.tradesData.totalPages;
        },
        openOrdersPageCount() {
            return this.$store.state.Orders.History.openOrdersData.totalPages;
        },
        otcSpotOrdersPageCount() {
            return this.$store.state.Orders.History.otcOrdersData.totalPages;
        },
        otcClosedOrdersPageCount() {
            return this.$store.state.Orders.History.otcClosedOrdersData.totalPages;
        },
        otcSpotTradesPageCount() {
            return this.$store.state.Orders.History.otcTradesData.totalPages;
        },
        otcOpenOrdersPageCount() {
            return this.$store.state.Orders.History.otcOpenOrdersData.totalPages;
        },
        futuresOrdersPageCount() {
            return this.$store.state.Futures.ordersData.totalPages;
        },
        futuresClosedOrdersPageCount() {
            return this.$store.state.Futures.closedOrdersData.totalPages;
        },
        futuresOpenOrdersPageCount() {
            return this.$store.state.Futures.openOrdersData.totalPages;
        },
        futuresTradesPageCount() {
            return this.$store.state.Futures.tradesData.totalPages;
        },
        fundingsPageCount() {
            return this.$store.state.Futures.fundingsData.totalPages;
        },
        positionsPagesCount() {
            const { tablePositions } = this.$store.state.Futures;
            return tablePositions.length % 12 === 0 ? tablePositions.length / 12 : Math.floor(tablePositions.length / 12) + 1;
        },
        positions() {
            return this.$store.state.Futures.tablePositions;
        },
        paginatedPositions() {
            return this.positions.filter((_, i) => i < 12 * this.activePositionsPage && i >= 12 * (this.activePositionsPage - 1));
        },
        paginatedBorrowings() {
            const { perPage } = this.$store.state.Orders.History.borrowingsData;
            return this.borrowings.filter((_, i) => i < perPage * this.borrowingsPage && i >= perPage * (this.borrowingsPage - 1));
        },
        NavBar() {
            if (this.isCurrentPlacementFutures) {
                return [
                    'Futures Orders',
                    'Active',
                    'Closed',
                    'Trades',
                    'Positions',
                    'Fundings',
                ];
            }
            return [
                'Spot Orders',
                'Active',
                'Closed',
                'Trades',
                'Borrowings',
            ];
        },
        currentPlacementId() {
            return this.$store.getters['Placements/activeTerminalPlacementId'];
        },
    },
    methods: {
        async setActiveOrdersTab(index) {
            await this.$store.dispatch('Orders/History/setActiveTerminalModuleTabIndex', index);
        },

        async mountModule() {
            if (this.$store.getters['Accounts/activeAccountID'] !== null) {
                try {
                    await this.$store.dispatch(getTablePositions(undefined));
                } catch {
                    // code crushed because of network error
                }
            }
        },
        checkPlacementById(id) {
            const currentPlacement = this.$store.state.Placements.placements.find(({ id: placementId }) => placementId === id);
            if (currentPlacement && currentPlacement.type.toLowerCase().indexOf('futures') !== -1) {
                this.activeOrderVariant = 1;
            } else {
                this.activeOrderVariant = 0;
            }
        },
        async switchHeader(e) {
            await this.$store.dispatch('Orders/History/setActiveTerminalModuleTabIndex', 0);
            this.activeOrderVariant = e;
        },
    },
    created() {
        this.mountModule();
    },
    mounted() {
        if (this.isOTC) {
            this.switchHeader(0);
        }
        if (this.activeAccountId) {
            this.setActiveOrdersTab(0);
        }
        this.checkPlacementById(this.currentPlacementId);
        if (!this.isOTC) {
            this.$store.dispatch('Orders/History/getBorrowings', undefined);
        }
    },
    watch: {
        async activeAccountId(value, oldValue) {
            if (!oldValue) {
                try {
                    await this.$store.dispatch(getTablePositions(undefined));
                } catch {
                    // code crushed because of network error
                }
            }

            this.spotOrdersPage = 1;
            this.closedSpotOrdersPage = 1;
            this.openSpotOrdersPage = 1;
            this.spotTradesPage = 1;
            this.otcSpotOrdersPage = 1;
            this.otcClosedSpotOrdersPage = 1;
            this.otcOpenSpotOrdersPage = 1;
            this.otcSpotTradesPage = 1;
            this.futuresTradesPage = 1;
            this.futuresOrdersPage = 1;
            this.futuresClosedOrdersPage = 1;
            this.futuresOpenOrdersPage = 1;
            this.fundingsPage = 1;
        },
        async currentPlacementId(value) {
            if (this.activeAccountId) {
                await this.$store.dispatch('Orders/History/setActiveTerminalModuleTabIndex', 0);
            }
            this.checkPlacementById(value);
        },
        async spotOrdersPage(val) {
            await this.$store.dispatch('Orders/History/getOrders', { page: val });
        },
        async closedSpotOrdersPage(val) {
            await this.$store.dispatch('Orders/History/getClosedOrders', { page: val });
        },
        async openSpotOrdersPage(val) {
            await this.$store.dispatch('Orders/History/getOpenOrders', { page: val });
        },
        async spotTradesPage(val) {
            await this.$store.dispatch('Orders/History/getTrades', { page: val });
        },
        async otcSpotOrdersPage(val) {
            await this.$store.dispatch('Orders/History/getOtcOrders', { page: val });
        },
        async otcClosedSpotOrdersPage(val) {
            await this.$store.dispatch('Orders/History/getOtcClosedOrders', { page: val });
        },
        async otcOpenSpotOrdersPage(val) {
            await this.$store.dispatch('Orders/History/getOtcOpenOrders', { page: val });
        },
        async otcSpotTradesPage(val) {
            await this.$store.dispatch('Orders/History/getOtcTrades', { page: val });
        },
        async futuresTradesPage(val) {
            await this.$store.dispatch(getTrades({ page: val }));
        },
        async futuresOrdersPage(val) {
            await this.$store.dispatch(getOrders({ page: val }));
        },
        async futuresClosedOrdersPage(val) {
            await this.$store.dispatch(getClosedOrders({ page: val }));
        },
        async futuresOpenOrdersPage(val) {
            await this.$store.dispatch(getOpenOrders({ page: val }));
        },
        async fundingsPage(val) {
            await this.$store.dispatch(getFundings({ page: val }));
        },
    },
});
</script>

<style lang="postcss" module="s">
.isOverfloved {
    max-height: 100%;
    overflow-y: auto;
    height: 100%;
}
.h100 {
    height: 100%;
}
.paginator {
    margin-top: auto;
    min-height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.relative {
    position: relative;
}
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 42px;
    border-bottom: 1px solid var(--cl-gray-light);
    padding: var(--m-xs) var(--m-m) var(--m-xs) var(--m-xxl);
    z-index: 2;
}
.subHeader {
    display: flex;
    align-items: center;
    position: relative;
    left: -10px;
}
</style>
